import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

const formatDate = (date) => {
  const day = ('0' + date.getDate()).slice(-2)
  const month = ('0' + (date.getMonth()+1)).slice(-2)
  const year = date.getFullYear()

  return `${day}-${month}-${year}`
}

export const useGSTStore = defineStore('gststore', () => {
  // Store Models
  const annual_expected_list = [
    "$0 to $74,999",
    "$75,000 to $149,999",
    "$150,000 to $1,999,999",
    "$2 million to $9,999,999",
    "$10 million to $19,999,999",
    "$20 million and over",
  ]
  const import_goods    = ref(false)
  const start_at        = ref(formatDate(new Date))
  const annual_expected = ref("$0 to $74,999")
  const company_lodge   = ref("Quarterly")
  const cash_basis      = ref("Cash")
  const do_bn           = ref(false)

  const update = (abn) => {
    import_goods.value    = !!abn.gst.import_goods
    start_at.value        = abn.gst.start_at
    annual_expected.value = abn.gst.annual_expected
    company_lodge.value   = abn.gst.company_lodge
    cash_basis.value      = abn.gst.cash_basis
    do_bn.value           = !!abn.business_name
  }

  return {
    annual_expected,
    annual_expected_list,
    company_lodge,
    cash_basis,
    import_goods,
    start_at,
    do_bn,
    update,
  }
}, { persist: true, })
