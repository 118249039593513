import { defineStore } from 'pinia'
import { ref } from 'vue'
import { router } from '@inertiajs/vue3'

export const useRouterStore = defineStore('routerStore', () => {

  const visit = (url) => {
    if(utm_source.value != null) {
      router.visit(`${url}?utm_source=${utm_source.value}`)
    } else {
      router.visit(url)
    }
  }

  const utm_source = ref()

  return {
    utm_source,
    visit
  };
}, { persist:false });
