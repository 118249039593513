import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useLoadingStore = defineStore('loadingstore', () => {
  const loading = ref(false)

  const setLoading = (bool) => {
    loading.value = bool
  }

  return {
    loading,
    setLoading,
  }
}, { persist:false })
