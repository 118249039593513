import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

export const useABNExtrasStore = defineStore('abnextrastore', () => {
  // Store Models
  const prev_abn                = ref("")
  const agricultural_property   = ref(false)
  const more_than_one_business  = ref(false)
  const why_applying            = ref("")
  const first_time              = ref(true)
  const do_bn                   = ref(false)
  const do_gst                  = ref(false)
  const gaddressConfirmationBox = ref(false)

  const why_applying_options = [
    'New Business in Australia',
    'Investment Purposes',
    'Purchased Existing Business',
    'Contractor/Sub-contractor',
    'To receive payment for services',
    'Change in Business Structure',
  ]

  const states = [
    "AUSTRALIAN CAPITAL TERRITORY",
    "NEW SOUTH WALES",
    "NORTHERN TERRITORY",
    "QUEENSLAND",
    "SOUTH AUSTRALIA",
    "TASMANIA",
    "VICTORIA",
    "WESTERN AUSTRALIA",
  ]

  const update = (abn) => {
    prev_abn.value                = abn.abn_extra_data?.prev_abn
    agricultural_property.value   = abn.abn_extra_data?.agricultural_property
    more_than_one_business.value  = !!abn.abn_extra_data?.more_than_one_business
    why_applying.value            = abn.abn_extra_data?.why_applying
    first_time.value              = !!abn.abn_extra_data?.first_time
    do_bn.value                   = !!abn.business_name
    do_gst.value                  = !!abn.gst
  }

  return {
    prev_abn,
    agricultural_property,
    more_than_one_business,
    why_applying,
    first_time,
    why_applying_options,
    states,
    do_bn,
    do_gst,
    update,
  }
},  { persist: true, })
