import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useLoadingStore } from '@stores/LoadingStore.js'

export const useBNStore = defineStore('bnstore', () => {
  // Store Models
  const use_bn          = ref(false)
  const renew_bn        = ref(false)
  const three_years     = ref(false)
  const birth_country   = ref("Australia")
  const birth_state     = ref("")
  const birth_city      = ref("")
  const do_gst          = ref(false)
  const checkName       = ref("")
  const responsemessage = ref("")
  const loadingStore    = useLoadingStore()

  const onSubmit = () => {
    const values = {
      name: checkName.value,
    }

    loadingStore.setLoading(true)
    axios.post('/api/bn/name-check', values)
      .then(response => {
        if (response.status  >= 200 && response.status <= 299){
          responsemessage.value = response.data
        } else {
          Swal.fire(
            'There has been an error',
            'An unexpected error has occurred. Please try again later or contact our support team for assistance.',
            'error',
          )
        }
      })
      .catch(error => {
        if(error.response.status >= 500 && error.response.status <= 599) {
          responsemessage.value = {code:500}
        }
      })
      .finally(() => {
        loadingStore.setLoading(false)
      })
  }

  const update = (abn) => {
    use_bn.value          = !!abn.business_name.use_bn
    renew_bn.value        = !!abn.business_name.renew_bn
    three_years.value     = !!abn.business_name.three_years
    birth_country.value   = abn.business_name.birth_country
    birth_state.value     = abn.business_name.birth_state
    birth_city.value      = abn.business_name.birth_city
    do_gst.value          = !!abn.gst
    checkName.value       = abn.business_name.name
  }

  return {
    onSubmit,
    birth_city,
    birth_country,
    birth_state,
    checkName,
    do_gst,
    renew_bn,
    responsemessage,
    three_years,
    update,
    use_bn,
  }
},  { persist: true, })
