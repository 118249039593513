import { defineStore } from 'pinia'
import { ref } from 'vue'

export const usePaymentStore = defineStore('paymentstore', () => {
  const client_secret = ref(null)
  const id = ref(null)

  const update = (abn) => {
    id.value = abn.id
    client_secret.value =
      abn?.services?.filter(service => service.client_secret != null)
      ?.[0]
      ?.client_secret ?? null;
  }

  return {
    client_secret,
    id,
    update,
  }
}, { persist:true })
