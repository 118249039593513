<template>
  <div class="w-16 h-16 block rotate-45 cube-folding">
    <span class="leaf1"></span>
    <span class="leaf2"></span>
    <span class="leaf3"></span>
    <span class="leaf4"></span>
  </div>
</template>

<style scoped>
.cube-folding {
  font-size: 0;
}
.cube-folding span {
  position: relative;
  width: 25px;
  height: 25px;
  transform: scale(1.1);
  display: inline-block;
}
.cube-folding .leaf1::before {
  background-color: rgb(168 162 158);
}
.cube-folding .leaf2 {
  transform: rotateZ(90deg) scale(1.1);
}
.cube-folding .leaf2::before {
  animation-delay: 0.3s;
  background-color: rgb(87 83 78);
}
.cube-folding .leaf3 {
  transform: rotateZ(270deg) scale(1.1);
}
.cube-folding .leaf3::before {
  animation-delay: 0.9s;
  background-color: rgb(87 83 78);
}
.cube-folding .leaf4 {
  transform: rotateZ(180deg) scale(1.1);
}
.cube-folding .leaf4::before {
  animation-delay: 0.6s;
  background-color: rgb(41 37 36);
}
</style>
