import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

const formatDate = (date) => {
  const day = ('0' + date.getDate()).slice(-2)
  const month = ('0' + (date.getMonth()+1)).slice(-2)
  const year = date.getFullYear()

  return `${day}-${month}-${year}`
}

export const useFindStore = defineStore('findstore', () => {
  // Store Models
  const birthdate         = ref(null)
  const confirmationemail = ref("")
  const email             = ref("")
  const firstname         = ref("")
  const lastname          = ref("")
  const middlename        = ref("")
  const phone             = ref("")
  const tfn               = ref(null)
  const number            = ref(null)
  const tfnNow            = ref(true)

  const register_day            = ref(formatDate(new Date))
  const main_business_activity  = ref("")
  const address                 = ref("")
  const unit_number             = ref("")
  const street_number           = ref("")
  const route                   = ref("")
  const address_line_2          = ref("")
  const locality                = ref("")
  const state                   = ref("")
  const postal_code             = ref("")

  const address_line_1 = computed(() => {
    return `${unit_number.value} ${street_number.value} ${route.value}`
  });

  const update = (abn) => {
    if(abn.register_day != null) {
      register_day.value     = abn.register_day
    }

    birthdate.value         = abn.birthdate
    confirmationemail.value = abn.email
    email.value             = abn.email
    firstname.value         = abn.firstname
    lastname.value          = abn.lastname
    middlename.value        = abn.middlename
    phone.value             = abn.phone
    tfn.value               = abn.tfn
    number.value            = abn.number
    tfnNow.value            = !!abn.tfn

    main_business_activity.value  = abn.main_business_activity
    address.value                 = abn.address
    street_number.value           = abn.street_number
    unit_number.value             = abn.unit_number
    route.value                   = abn.route
    address_line_1.value          = abn.address_line_1
    address_line_2.value          = abn.address_line_2
    locality.value                = abn.locality
    state.value                   = abn.state
    postal_code.value             = abn.postal_code
  }

  return {
    birthdate,
    confirmationemail,
    email,
    firstname,
    lastname,
    middlename,
    phone,
    tfn,
    number,
    tfnNow,
    update,
    register_day,
    main_business_activity,
    address,
    street_number,
    unit_number,
    route,
    address_line_1,
    address_line_2,
    locality,
    state,
    postal_code,
  }
}, { persist: true, })
